<template>
    <Transition name="slide-fade">
        <div
            v-if="show"
            :class="{
                'cart--general': !detailPage,
                'cart--detail': detailPage && message,
            }"
        >
            <div class="d-flex flex-column cart" ref="item">
                <div v-if="message" class="d-flex message">
                    <div class="flex-grow-1 equal-small-2 message__text">
                        {{ t('cartToast.added') }}
                    </div>
                    <FormButton
                        class="l-button--icon-only l-button--secondary l-button--size-30 flex-shrink-0 message__button"
                        @click="close"
                    >
                        <IconsClose />
                    </FormButton>
                </div>
                <div
                    class="d-flex justify-content-between product flex-wrap"
                    :class="{
                        'align-items-center': !payment,
                        'flex-column flex-640-row align-items-start product--payment':
                            payment,
                    }"
                >
                    <div
                        class="product__content"
                        :class="{
                            'product__content--payment': payment,
                        }"
                    >
                        <div
                            class="equal-base equal-base--tighter-bold-uppercase"
                        >
                            <span class="product-quantity">{{ quantity }}</span>
                            {{ product?.title }}
                        </div>
                        <div>
                            <span
                                class="equal-small-2 equal-small-2--bold-uppercase"
                                :class="{
                                    'cart__price--offer': priceInfo?.action,
                                }"
                            >
                                {{ priceInfo?.currency }}
                                {{
                                    priceInfo?.action
                                        ? priceInfo?.incl_tax
                                        : priceInfo?.original_incl_tax
                                }}
                            </span>
                            <span
                                class="equal-small-3 cart__price-invalid"
                                v-if="priceInfo?.action"
                            >
                                {{ priceInfo?.currency }}
                                {{ priceInfo?.original_incl_tax }}
                            </span>
                        </div>
                    </div>
                    <div v-if="!payment" class="buttons">
                        <ProductPicker
                            :product="product"
                            placement="top-end"
                            :availabilities="availabilities"
                            :detail-page="id === 'detail'"
                        />
                    </div>
                    <div
                        v-else
                        class="d-flex flex-column flex-640-row buttons"
                        :class="{
                            'buttons--payment': payment,
                        }"
                    >
                        <FormButton
                            :label="t('cartToast.displayBasket')"
                            class="l-button--secondary l-button--outlined l-button--auto-width buttons__cart"
                            :size40="true"
                            @click="showBasket"
                        />
                        <FormButton
                            :label="t('cartToast.toCheckout')"
                            class="l-button--auto-width"
                            :size40="true"
                            @click="toCheckout"
                            v-if="!belowMinimumOrderValue"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { navigateTo } from 'nuxt/app';
import { useBasketStore } from '~/stores/basket';

const props = defineProps({
    priceInfo: {
        type: Object,
        required: false,
        validator: (value) => {
            return value?.currency && value?.incl_tax;
        },
    },
    payment: {
        type: Boolean,
        default: true,
    },
    quantity: {
        type: Number,
        required: false,
        default: 1,
    },
    message: {
        type: Boolean,
        default: false,
    },
    availabilities: {
        type: Object,
        required: false,
    },
    id: {
        type: String,
        required: true,
    },
    storyShow: {
        type: Boolean,
        required: false,
        default: false,
    },

    storyProduct: {
        type: Object,
        required: false,
        default: {},
    },
});

const { t } = useI18n();
const payment = toRef(props, 'payment');
const basketStore = useBasketStore();
const checkoutRoute = useCheckoutRoute();

const item = ref(null);

const detailPage = computed(() => {
    return props.id === 'detail';
});

const show = computed(() => {
    return basketStore.cartModalVal(props.id, 'show') || props.storyShow;
});

const product = computed(() => {
    return basketStore.cartModalVal(props.id, 'product') || props.storyProduct;
});

const belowMinimumOrderValue = computed(() => {
    return !basketStore?.basket?.passes_order_minimum;
});

function toCheckout() {
    close();
    navigateTo(checkoutRoute.value.path);
}

function close() {
    basketStore.setCartModal(props.id, 'show', false);
}

onMounted(() => {
    window.addEventListener('click', (e) => {
        if (show.value && props.message && !item?.value?.contains(e.target)) {
            close();
        }
    });
});

function showBasket() {
    close();
    const el = document.getElementById('cartButton');
    el.click();
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slide-fade-enter-active {
    transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(100px);
    opacity: 0;
}

.cart--general {
    width: 50%;
    left: 25%;
    position: fixed;
    bottom: 40px;
    z-index: 10000;
    @include media-breakpoint-down(640) {
        left: 5%;
        width: 90%;
    }
}
.cart--detail {
    position: relative;
}

.cart {
    z-index: 100 !important;
    display: flex;
    max-width: 100%;

    background-color: $color-dark-02;
    border-radius: 35px;
    border: 1px solid $color-dark-disabled;

    &__price {
        &--offer {
            color: #c62424;
        }
    }

    &__price-invalid {
        color: #999999;
        text-decoration: line-through;
        margin-left: 5px;
    }
}

.message {
    margin: 0 30px;
    padding: 15px 0;
    border-bottom: 1px solid $color-dark-01;

    &__text {
        margin: auto 0;
    }

    &__button {
        margin: auto 0 auto 5px;
    }
}

.product {
    height: 70px;
    margin: 0 10px 0 30px;
    padding: 15px 0;
    overflow: hidden;

    &--payment {
        height: unset;
        margin: 0 30px;
    }

    &__content {
        overflow: hidden;
        margin-bottom: 10px;
        white-space: nowrap;
        width: calc(100% - 90px);
        .equal-base {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &--payment {
            @include media-breakpoint-down(640) {
                margin-bottom: 10px;
            }
        }
    }
}

.buttons {
    margin: -5px 0 auto 5px;

    @include media-breakpoint-down(640) {
        margin: -5px 0 auto 0;
    }

    &--payment {
        margin: auto 0 auto 0;

        @include media-breakpoint-down(640) {
            width: 100%;

            button {
                width: 100%;
                max-width: unset;
            }
        }
    }

    &__cart {
        margin-right: 15px;

        @include media-breakpoint-down(640) {
            margin-right: unset;
            margin-bottom: 10px;
        }
    }
}
.product-quantity:after {
    content: 'x ';
    display: inline-block;
    font-size: 16px;
    margin-left: 6px;
    text-transform: lowercase;
    height: 16px;
    margin-top: -1px;
    vertical-align: top;
}
</style>
